#iframeContainer {
  width: 100%;
  height: 100%;
}

.buttons-bar {
  padding: 8px;
  margin-bottom: 50px;
}

.bx-xs {
  font-size: 1.4em !important;
}

.field-search {
  min-height: 200px;
  padding: 16px;
}

.footer-buttons {
  padding: 0 24px;
}

.ion-content-result {
  min-height: 30px;
  max-height: 300px;
  padding-right: 16px;
  overflow-y: auto;
}

.ion-content-table-result {
  height: fit-content;
  max-height: 360px;
  overflow-y: auto;
}

.ion-icon-slot {
  position: relative;
  top: 2px;
}

.item-border-bottom {
  border-bottom: 0.3px solid #8c8c8c41;
}

.item-search-menu {
  border-radius: 6px !important;
  border: 1px solid #8c8c8c41;
  margin: 10px;
}

.schedule-info {
  font-size: 1rem;
  line-height: 0px;
  position: relative;
  text-align: right;
  top: -14px;
}

.pointer {
  cursor: pointer;
}

.custom-tooltip {
  font-size: 0.8em;
}

.float-right {
  position: absolute;
  right: 0;
  margin-right: 16px;
}

.title-modal {
  float: left;
  position: relative;
  margin-left: 16px;
  padding-left: 6px;
}

.white-space {
  white-space: pre-wrap !important;
  text-align: left;
}

.input-date {
  max-width: 180px;
  max-height: 8px !important;
}

.max-height {
  height: 100%;
}

/* scroll cajas de texto*/
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(var(--ion-color-primary-rgb), 0.75);
}

/*
  Colores de Fuentes de acuerdo a la paleta de colores
  Recomendación de uso para cuando la propiedad color de ionic no se aplica al componente
  ej: etiquetas i, p, h
*/
.font-color-primary {
  color: var(--ion-color-primary) !important;
}

.font-color-secondary {
  color: var(--ion-color-secondary) !important;
}

.font-color-tertiary {
  color: var(--ion-color-tertiary) !important;
}

.font-color-success {
  color: var(--ion-color-success) !important;
}

.font-color-warning {
  color: var(--ion-color-warning) !important;
}

.font-color-danger {
  color: var(--ion-color-danger) !important;
}

.font-color-light {
  color: var(--ion-color-light) !important;
}

.font-color-medium {
  color: var(--ion-color-medium) !important;
}

.font-color-dark {
  color: var(--ion-color-dark) !important;
}

.font-size-8 {
  font-size: 0.8em !important;
}

.form-buttons {
  padding-bottom: 60px;
}

.border-warning {
  border-left: 4px solid var(--ion-color-warning) !important;
  margin-bottom: 15px;
}

.border-secondary {
  border-left: 4px solid var(--ion-color-secondary) !important;
  margin-bottom: 15px;
}

.border-dark {
  border-left: 4px solid var(--ion-color-dark) !important;
  margin-bottom: 15px;
}

.border-light {
  border-left: 4px solid var(--ion-color-light) !important;
  margin-bottom: 15px;
}

.border-danger {
  border-left: 4px solid var(--ion-color-danger) !important;
  margin-bottom: 15px;
}

.border-primary {
  border-left: 4px solid var(--ion-color-primary) !important;
  margin-bottom: 15px;
}

.border-success {
  border-left: 4px solid rgba(var(--ion-color-success-rgb), 0.8) !important;
  margin-bottom: 15px;
}

.border-orange {
  border-left: 4px solid #e75410 !important;
  margin-bottom: 15px;
}

.label-title {
  font-weight: 600;
  padding-left: 4px;
}

.text-success {
  width: 120px !important;
  --background: rgba(var(--ion-color-success-rgb), 0.8) !important;
  --color: #fff !important;
}

.text-secondary {
  width: 120px !important;
  --background: var(--ion-color-secondary) !important;
  --color: #fff !important;
}

.text-orange {
  width: 120px !important;
  --background: #e75410 !important;
  --color: #fff !important;
}

.text-primary {
  width: 120px !important;
  --background: var(--ion-color-primary) !important;
  --color: #fff !important;
}

.text-danger {
  width: 120px !important;
  --background: var(--ion-color-danger) !important;
  --color: #fff !important;
}

.text-light {
  width: 120px !important;
  --background: var(--ion-color-light) !important;
  --color: #fff !important;
}

.text-warning {
  width: 120px !important;
  --background: var(--ion-color-warning) !important;
  --color: #fff !important;
}

.text-dark {
  width: 120px !important;
  --background: var(--ion-color-dark) !important;
  --color: #fff !important;
}

.label-input {
  font-weight: 500;
}

.tableauViz {
  width: 100%;
  height: 100%;
}

/*
Ventanas Modales para Ionic V6
Tamaños: [sm=20 , md= 40, lg=60, xl=90, xxl=100]  % de ancho
El alto debe ser dinámico aunque con un minimo de 10%
*/

.modal-backdrop {
  &::part(content) {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  }
}

.modal-xxl-float-right {
  &::part(content) {
    border: 1px solid var(--ion-color-light);
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    width: 40%;
  }
}

.modal-xxl-float-left {
  &::part(content) {
    border: 1px solid var(--ion-color-light);
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    width: 40%;
  }
}

.modal-height-lg {
  &::part(content) {
    border: 1px solid var(--ion-color-light);
    display: block;
    height: 80%;
    position: absolute;
    width: 50%;
  }
}

.modal-sm {
  &::part(content) {
    width: 20%;
    min-height: 10%;
    position: absolute;
    display: block;
    border: 1px solid var(--ion-color-light);
    border-radius: 3px;
  }
}

.modal-md {
  &::part(content) {
    width: 40%;
    min-height: 10%;
    position: absolute;
    display: block;
    border: 1px solid var(--ion-color-light);
    border-radius: 3px;
  }
}

.modal-view-img {
  &::part(content) {
    width: 50%;
    min-height: 80%;
    position: absolute;
    display: block;
    border: 1px solid var(--ion-color-light);
    border-radius: 3px;
  }
}

.modal-lg {
  &::part(content) {
    width: 50%;
    min-height: 10%;
    position: absolute;
    display: block;
    border: 1px solid var(--ion-color-light);
    border-radius: 3px;
  }
}

.cmodal-xl {
  &::part(content) {
    width: 90%;
    min-height: 10%;
    position: absolute;
    display: block;
    border: 1px solid var(--ion-color-light);
    border-radius: 3px;
  }
}

.modal-xxl {
  &::part(content) {
    width: 100%;
    min-height: 10%;
    position: absolute;
    display: block;
    border: 1px solid var(--ion-color-light);
    border-radius: 3px;
  }
}

ion-modal.modal-sm,
ion-modal.modal-md,
ion-modal.modal-lg,
ion-modal.cmodal-xl,
ion-modal.modal-xxl {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      overflow: auto;
      max-height: 95vh;
    }
  }
}

.content-favorites {
  height: 300px !important;
}

@media only screen and (max-width: 768px) {
  .border-warning {
    border-left: 0px solid var(--ion-color-warning) !important;
    margin-bottom: 15px;
  }
  .border-secondary {
    border-left: 0px solid var(--ion-color-secondary) !important;
    margin-bottom: 15px;
  }
  .border-dark {
    border-left: 0px solid var(--ion-color-dark) !important;
    margin-bottom: 15px;
  }
  .border-light {
    border-left: 0px solid var(--ion-color-light) !important;
    margin-bottom: 15px;
  }
  .border-danger {
    border-left: 0px solid var(--ion-color-danger) !important;
    margin-bottom: 15px;
  }
  .border-primary {
    border-left: 0px solid var(--ion-color-primary) !important;
    margin-bottom: 15px;
  }
  .border-success {
    border-left: 0px solid rgba(var(--ion-color-success-rgb), 0.8) !important;
    margin-bottom: 15px;
  }
  .border-orange {
    border-left: 0px solid #e75410 !important;
    margin-bottom: 15px;
  }
  .title-folio {
    font-size: 0.9em !important;
  }
  .content-favorites,
  .modal-content,
  .modal-lg,
  .modal-md,
  .horizontal-modal-md,
  .frm-anamnesis-modal,
  .modal-xxl-float-right,
  .modal-height-lg,
  .modal-xxl-float-left {
    &::part(content) {
      border: 1px solid var(--ion-color-light);
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }
}

// TODO: Eliminar estas 5 clases luego de terminar el componente de tabla generico
.ion-badge-status-pending {
  padding: 10px;
  text-align: center;
  background-color: #fde6a3;
  color: #7a6f37;
}

.ion-badge-status-approved {
  padding: 10px;
  text-align: center;
  background-color: #bef0c0;
  color: #357c39;
}

.ion-badge-status-rejected {
  padding: 10px;
  text-align: center;
  background-color: #ffcdd2;
  color: #bf2d2d;
}

.ion-badge-status-pre-approved {
  padding: 10px;
  text-align: center;
  background-color: #60a7f3;
  color: #13457a;
}

.ion-badge-status-managed {
  padding: 10px;
  text-align: center;
  background-color: #bef0c0;
  color: #357c39;
}

.ion-color-check {
  --ion-color-base: var(--ion-color-check);
  --ion-color-base-rgb: var(--ion-color-check-rgb);
  --ion-color-contrast: var(--ion-color-check-contrast);
  --ion-color-contrast-rgb: var(--ion-color-check-contrast-rgb);
  --ion-color-shade: var(--ion-color-check-shade);
  --ion-color-tint: var(--ion-color-check-tint);
}

.ion-color-nocheck {
  --ion-color-base: var(--ion-color-nocheck);
  --ion-color-base-rgb: var(--ion-color-nocheck-rgb);
  --ion-color-contrast: var(--ion-color-nocheck-contrast);
  --ion-color-contrast-rgb: var(--ion-color-nocheck-contrast-rgb);
  --ion-color-shade: var(--ion-color-nocheck-shade);
  --ion-color-tint: var(--ion-color-nocheck-tint);
}

ion-toast.app-prompt-success-toast {
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
}

ion-toast.app-prompt-success-toast::part(container) {
  border-bottom: 8px solid var(--ion-color-secondary);
}

ion-toast.app-prompt-success-toast::part(icon) {
  border: 1px solid var(--ion-color-secondary);
  border-radius: 32px;
  color: var(--ion-color-secondary);
  font-size: 1.5em;
  padding: 8px;
  margin-right: 16px;
}

ion-toast.app-prompt-success-toast::part(header) {
  color: var(--ion-color-secondary);
  font-size: 1.2em;
  font-weight: 800;
}
