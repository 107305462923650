/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// * Todos estos estilos corresponden al componente de zoom si se borran van a quedar mal la telemedicina
.media-preview-container {
  height: 645px;
}

#content_container {
  background-color: #242424 !important;
}

.zm-btn {
  width: 100%;
}

.header-container-primary-color-close-icon[data-v-1d6fd580] {
  position: fixed !important;
}

.meeting-client-inner {
  width: auto !important;
}

ion-accordion::part(content) {
  background: #671e75 !important;
}


//CSS para mostrar colores en los alerts de ionic.
.app-prompt-info .alert-wrapper {
  min-width: 350px;

  .alert-head {
    background: rgba(var(--ion-color-secondary-rgb), 0.25);

    h2 {
      color: var(--ion-color-secondary);
      font-weight: 500;
    }
  }

  .alert-message {
    margin-top: 16px;
  }

  .alert-button {
    color: var(--ion-color-secondary);
    font-weight: bold;
  }
}




.app-prompt-reminder .alert-wrapper {
  min-width: 350px;

  .alert-head {
    background: var(--ion-color-secondary);

    h2 {
      color: var(--ion-color-secondary-contrast);
      font-weight: 500;
    }
  }

  .alert-message {
    margin-top: 16px;
  }

  .alert-button {
    color: var(--ion-color-warning-shade);
    font-weight: bold;
  }
}

.app-prompt-danger .alert-wrapper {
  min-width: 350px;

  .alert-head {
    background: var(--ion-color-danger);

    h2 {
      color: var(--ion-color-danger-contrast);
      font-weight: 500;
    }
  }

  .alert-message {
    margin-top: 16px;
  }

  .alert-button {
    color: var(--ion-color-danger-shade);
    font-weight: bold;
  }
}

.app-prompt-success .alert-wrapper {
  min-width: 350px;

  .alert-head {
    background: var(--ion-color-success);

    h2 {
      color: var(--ion-color-success-contrast);
      font-weight: 500;
    }
  }

  .alert-message {
    margin-top: 16px;
  }

  .alert-button {
    color: var(--ion-color-success-shade);
    font-weight: bold;
  }
}

.app-prompt-warning .alert-wrapper {
  min-width: 350px;

  .alert-head {
    background: var(--ion-color-warning);

    h2 {
      color: var(--ion-color-warning-contrast);
      font-weight: 500;
    }
  }

  .alert-message {
    margin-top: 16px;
  }

  .alert-button {
    color: var(--ion-color-warning-contrast);
    font-weight: bold;
  }
}

// Hacer que los items del ionic-selectable se muestren completos en dos o mal lineas si estos son muy largos
.sc-ion-label-md-h,
.sc-ion-label-md-s {
  word-wrap: break-word !important;
  white-space: normal !important;
  overflow-wrap: break-word;
}

@media (prefers-color-scheme: dark) {
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(2);
  }
  input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(2);
  }
}
