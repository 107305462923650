/* Estilos modificados para los componentes de Ionic */

ion-icon {
  pointer-events: none;
}

h1 {
  font-weight: 400 !important;
}

p,
h6 {
  font-weight: 500 !important;
}

ion-button {
  --box-shadow: none;
}

ion-card {
  box-shadow: none !important;
  border: 1px #dedede solid !important;
}

ion-datetime {
  padding: 8px;
}

ion-header {
  border-bottom: 0px solid #ccc;

  ion-title {
    padding-left: 14px;
  }
}

.popover {
  background-color: transparent;
  display: contents;
}

ion-popover {
  ion-backdrop {
    opacity: 0.1 !important;
  }

  &::part(content) {
    width: auto;
    cursor: pointer;
  }
}

ion-list-header ion-label h2 {
  font-weight: bold !important;
  font-size: 1em;
}

ion-select {
  color: rgba(var(--ion-color-darks-rgb), 1) !important;
}

ion-list-header i {
  color: var(--ion-color-secondary);
  float: right;
  font-size: 1.4rem;
}

ion-item {
  --border-color: rgb(233, 230, 230);
  
}

ion-item {
  ion-button {
    height: 30px;
  }
}

ion-list-header ion-label {
  font-weight: bold;
  opacity: 0.9;
}

ion-list-header i {
  color: var(--ion-color-secondary);
  float: right;
  font-size: 1.4rem;
}

.sc-ion-label-md-h,
.sc-ion-label-md-s {
  word-wrap: break-word !important;
  white-space: normal !important;
  overflow-wrap: break-word;
}

ion-input:hover {
  outline: none !important;
  outline-offset: 0 !important;
}

ion-input:not(:focus) {
  outline: none !important;
  outline-offset: 0 !important;
}

ion-input.has-focus {
  outline: none !important;
  outline-offset: 0 !important;
}

ion-input:not(:focus) {
  outline: none !important;
  outline-offset: 0 !important;
}

input[type='text']:focus {
  outline: none !important;
  outline-offset: 0 !important;
}
